import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import {
  Player,
  ControlBar,
  BigPlayButton,
  LoadingSpinner,
  VolumeMenuButton,
  Bezel,
} from "video-react"
import { withBreakpoints } from "react-breakpoints"
import scrollToElement from "scroll-to-element"
import VisibilitySensor from "react-visibility-sensor"
import _ from "lodash"

import Close from "../svg/Close"
import FigureBox, { boxFormats } from "./FigureBox"

// import { connect } from "react-redux"
// import { hideHeader, showHeader } from "../actions"


class InlinePlayerContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      labelText: `Play`,
      fullscreen: false,
      videoStarted: false,
      hasClicked: false,
      isVisible: false,
      src: null,
      hideMouseCursor: false,
      playerCurrentTime: 0,
      documentIsFullscreen: false,
      positionStyle: {
        posion: `absolute`,
        left: 0,
        top: 0,
      },
    }
    this.setVisible = this.setVisible.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.mouseMoveDebounce = _.debounce(this.mouseMoveDebounce.bind(this), 1250)
  }

  handleMouseMove(event) {
    event.persist()

    // reset mouse cursor state
    this.setState({
      hideMouseCursor: false,
    })

    // trigger debounced event
    this.mouseMoveDebounce(event)
  }

  mouseMoveDebounce(event) {
    if (
      this.props.parent.state.playerIsFullscreen &&
      this.props.detectedEnvironment.isMouseDetected
    ) {
      this.setState({
        hideMouseCursor: true,
      })
    }
  }

  setVisible(visible = false) {
    // console.log(visible)

    // set visible
    this.setState({
      isVisible: visible,
    })

    // set src on first viewport hit
    if (visible && !this.state.src) {
      this.setState({
        src: this.props.src,
      })
      // load and play
      this.load()
      this.play()
    }

    // this.load()
  }

  componentDidMount() {
    if (this.refs.player !== undefined) {
      // state change subscribe
      this.refs.player.subscribeToStateChange(this.videoStateChange.bind(this))

      // autoplay only if specified
      if (this.props.autoplay) {
        // start from begining
        this.restart(this.props.loopFrom)

        // play when ready
        this.refs.player.play()
        setTimeout(() => {
          this.load()
          this.play()
        }, 10)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.isVisible &&
      !this.state.documentIsFullscreen &&
      this.props.parent.state.playerIsFullscreen
    ) {
      this.mute()
      this.reset()
    } else if (
      this.props.parent.state.playerIsFullscreen &&
      this.state.hasClicked
    ) {
      this.unmute()
    }

    if (prevProps.position.x !== this.props.position.x) {
      this.setState({
        positionStyle: {
          posion: `absolute`,
          left: this.props.position.x,
          top: this.props.position.y,
        },
      })
    }
  }

  handleMouseDown(event) {
    let isFullscreen = this.props.parent.state.playerIsFullscreen
    let hasClicked = this.state.hasClicked

    // on click when not fullscreen
    if (!isFullscreen && !hasClicked) {
      // unmute and play video
      this.open()
      // when out of fullscreen, pause
    } else {
      this.reset()
    }
  }

  // set state when user click on video
  open() {
    this.unmute()
    this.restart(this.state.playerCurrentTime)

    this.props.parent.setState({
      playerIsFullscreen: true,
    })

    this.setState({
      labelText: ``,
      hasClicked: true,
      hideMouseCursor: false,
    })
    if (this.props.srcFull) {
      this.setState({
        src: this.props.srcFull,
      })
    }

    // hide header
    if (this.props.hideNavOnPlay) {
      // this.props.hideHeader()
    }

    // scroll to video on click
    setTimeout(() => {
      scrollToElement(ReactDOM.findDOMNode(this.refs.container), {
        duration: 250,
        offset: 0,
        ease: `inOutQuad`,
      })
      this.load()
      this.play()
    }, 250)
  }

  // set state when user click on video, or video has ended
  reset() {
    // mute video again
    this.mute()

    // restart video
    this.restart(this.props.loopFrom)

    // show header again
    // this.props.showHeader()

    this.setState({
      labelText: `Play`,
      hasClicked: false,
      hideMouseCursor: false,
      src: this.props.src,
    })

    this.props.parent.setState({
      playerIsFullscreen: false,
    })
    setTimeout(() => {
      this.load()
      this.play()
    }, 10)
  }

  restart(time = 0) {
    if (this.refs.player) {
      this.refs.player.seek(time)
      this.refs.player.play()
    }
  }

  play(playerState) {
    if (this.refs.player) this.refs.player.play()
  }

  pause(playerState) {
    if (this.refs.player) this.refs.player.pause()
  }

  mute() {
    if (this.refs.player) this.refs.player.muted = true
  }

  unmute() {
    if (this.refs.player) this.refs.player.muted = false
  }

  load() {
    if (this.refs.player) this.refs.player.load()
  }

  loop() {
    this.restart(this.props.loopFrom)
  }

  videoStateChange(playerState, playerPrevState) {
    // state the video has started
    if (!this.state.videoStarted && playerState.currentTime > 0) {
      this.setState({
        videoStarted: true,
      })
    }

    // when not in fullscreen, loop every VIDEO_LOOP_MAX_SECONDS second
    // stop looping when state for click has changed
    if (
      (playerState.currentTime >= this.props.loopEvery || playerState.ended) &&
      !this.props.parent.state.playerIsFullscreen &&
      !this.state.hasClicked
    ) {
      this.loop()
    }

    if (playerState.isFullscreen) {
      document.body.classList.toggle(`video-react-full-window`, true)
      this.setState({
        documentIsFullscreen: true,
      })
    } else {
      document.body.classList.toggle(`video-react-full-window`, false)
      this.setState({
        documentIsFullscreen: false,
      })
    }

    if (this.props.parent.state.playerIsFullscreen) {
      this.setState({
        playerCurrentTime: playerState.currentTime,
      })
    }

    // when player has ended, reset to original state
    if (
      this.props.parent.state.playerIsFullscreen &&
      playerState.ended &&
      !this.state.documentIsFullscreen
    ) {
      this.setState({
        playerCurrentTime: 0,
      })
      this.reset()
    }
  }

  containerClassName() {
    let className = []

    className.push(`inline_player__container`)

    if (this.props.controls && !this.props.parent.state.playerIsFullscreen) {
      className.push(`with-controls`)
    }

    return className.join(` `)
  }

  cursorClassName() {
    let className = []

    className.push(`inline_player__cursor`)

    if (this.state.hideMouseCursor) {
      className.push(`is-faded`)
    }

    if (!this.props.controls) {
      className.push(`is-hidden`)
    }

    return className.join(` `)
  }

  controlBarClassName() {
    return this.props.parent.state.playerIsFullscreen
      ? `is-visible`
      : `is-faded`
  }
  render() {
    // console.log(this.props)
    return (
      <VisibilitySensor
        partialVisibility={["top", "bottom"]}
        offset={{ top: -300, bottom: -300 }}
        onChange={this.setVisible}
      >
        <div
          ref="container"
          className={this.containerClassName()}
          onMouseMove={this.handleMouseMove}
          aria-hidden="true"
        >
          <nav
            className="inline_player__ui_zone"
            onClick={e => this.handleMouseDown(e)}
          >
            <span
              className={this.cursorClassName()}
              style={
                this.props.detectedEnvironment.isMouseDetected
                  ? this.state.positionStyle
                  : null
              }
            >
              <Close color="#ffffff" /> {this.state.labelText}
            </span>
          </nav>
          <Player
            ref="player"
            controls={true}
            loop={this.props.loop}
            muted={true}
            playsInline
            fluid={false}
            width={100}
            height={100}
            className="inline_player__player"
            src={
              this.props.autoplay && !this.props.srcFull
                ? this.props.src
                : this.state.src
            }
          >
            {this.props.poster && (
              <div
                className={`inline_player__poster d-block d-sm-none ${
                  this.state.videoStarted ? `is-faded` : ``
                }`}
              >
                {/* visible only on mobile devices */}
                <FigureBox
                  format={boxFormats.auto}
                  backgroundSize={`cover`}
                  backgroundPosition={`center center`}
                  preferWebP={false}
                  source={this.props.poster}
                />
              </div>
            )}
            <Bezel className="is-hidden" />
            <LoadingSpinner className="is-hidden" />
            <BigPlayButton className="is-hidden" />
            <ControlBar autoHide={false} className={this.controlBarClassName()}>
              <VolumeMenuButton disabled />
            </ControlBar>
          </Player>
        </div>
      </VisibilitySensor>
    )
  }
}

InlinePlayerContainer.defaultProps = {
  controls: true,
  autoplay: true,
}

InlinePlayerContainer.propTypes = {
  parent: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  poster: PropTypes.object,
  resume: PropTypes.bool.isRequired,
  autoplay: PropTypes.bool.isRequired,
  controls: PropTypes.bool,
}

// export default

export default InlinePlayerContainer
