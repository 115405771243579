import React from "react"
import PropTypes from "prop-types"
import ReactCursorPosition from "@appinfini/react-cursor-position"

import InlinePlayerContainer from "./InlinePlayerContainer"

class InlinePlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playerIsFullscreen: false,
      playerFullScreenClassName: `is-fullscreen`,
      playerPlaceholderClassName: `is-placeholder`,
    }
  }

  className() {
    let classes = [`inline_player`]

    if (this.state.playerIsFullscreen || !this.props.collapsed) {
      classes.push(this.state.playerFullScreenClassName)
    }

    if (!this.props.controls) {
      classes.push(this.state.playerPlaceholderClassName)
    }

    return classes.join(` `)
  }

  render() {
    return (
      <div className={`${this.className()}`}>
        <ReactCursorPosition>
            <InlinePlayerContainer
              parent={this}
              src={this.props.src}
              srcFull={this.props.srcFull}
              resume={this.props.resume}
              autoplay={this.props.autoplay}
              hideNavOnPlay={this.props.hideNavOnPlay}
              controls={this.props.controls}
              loop={this.props.loop}
              loopFrom={this.props.loopFrom}
              loopEvery={this.props.loopEvery}
              poster={this.props.poster}
            />
        </ReactCursorPosition>
      </div>
    )
  }
}

InlinePlayer.defaultProps = {
  resume: false,
  autoplay: true,
  lazy: false,
  hideNavOnPlay: false,
  collapsed: true,
  controls: true,
  loop: false,
  loopFrom: 2,
  loopEvery: 9,
}

InlinePlayer.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.object,
  resume: PropTypes.bool.isRequired,
  autoplay: PropTypes.bool.isRequired,
  lazy: PropTypes.bool,
  hideNavOnPlay: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  loopEvery: PropTypes.number,
}

export default InlinePlayer
